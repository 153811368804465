import { Icon } from '@iconify/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { timeAgo } from '../../utility'

export default function Post(data) {
  return (
    <div className={`cs_post cs_style_1 ${data?.variant}`}>
      <Link to={data?.href} className='cs_post_thumb cs_view_mouse'>
        <img src={data?.thumbnail} alt={data?.title} />
      </Link>
      <div className='cs_post_info'>
        <div>
          <div className='cs_post_meta'>
            <div className='cs_posted_by'>{timeAgo(data?.created_at)}</div>
            {data?.socialShare && (
              <div className='cs_post_social'>
                <Link to='/' className='cs_center rounded-circle'>
                  <Icon icon='fa-brands:linkedin-in' />
                </Link>
                <Link to='/' className='cs_center rounded-circle'>
                  <Icon icon='fa-brands:facebook-f' />
                </Link>
                <Link to='/' className='cs_center rounded-circle'>
                  <Icon icon='fa-brands:twitter' />
                </Link>
              </div>
            )}
          </div>
          <h2 className='cs_post_title cs_semibold cs_fs_18'>
            <Link to={`/artikel/${data?.slug}/${data?.id}/detail`}>
              {data?.title}
            </Link>
          </h2>
        </div>
        {data?.btnText && (
          <div className='cs_heading_color cs_medium'>
            <Link to={data?.href} className='cs_post_btn'>
              {data?.btnText}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
