import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import About from './Pages/About'
import Announcement from './Pages/Announcement'
import AnnouncementDetails from './Pages/AnnouncementDetails'
import Appointments from './Pages/Appointments'
import Article from './Pages/Article'
import ArticleDetails from './Pages/ArticleDetails'
import Contact from './Pages/Contact'
import DoctorDetails from './Pages/DoctorDetails'
import Doctors from './Pages/Doctors'
import ErrorPage from './Pages/ErrorPage'
import Gallery from './Pages/Gallery'
import Home from './Pages/Home'
import HomeStyle5 from './Pages/HomeStyle5'
import HomeStyle6 from './Pages/HomeStyle6'
import PricingPlan from './Pages/PricingPlan'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import TermsConditions from './Pages/TermsConditions'

import Regulasi from './Pages/Regulasi'
import Sejarah from './Pages/Sejarah'
import Pengurus from './Pages/SusunanPengurus'
import Timetable from './Pages/Timetable'
import Layout from './components/Layout'
import Layout2 from './components/Layout/Layout2'
import Layout5 from './components/Layout/Layout5'
function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='about' element={<About />} />
        <Route path='doctors' element={<Doctors />} />
        <Route path='doctors/:doctorId' element={<DoctorDetails />} />
        <Route path='artikel' element={<Article />} />
        <Route path='artikel/:slug/:id/detail' element={<ArticleDetails />} />
        <Route path='appointments' element={<Appointments />} />
        <Route path='pengumuman' element={<Announcement />} />
        <Route
          path='pengumuman/:departmentId'
          element={<AnnouncementDetails />}
        />
        <Route path='sejarah' element={<Sejarah />} />
        <Route path='susunan-pengurus' element={<Pengurus />} />
        <Route path='regulasi' element={<Regulasi />} />
        <Route path='pricing-plan' element={<PricingPlan />} />
        <Route path='gallery' element={<Gallery />} />
        <Route path='timetable' element={<Timetable />} />
        <Route path='contact' element={<Contact />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='terms-conditions' element={<TermsConditions />} />
      </Route>
      <Route element={<Layout2 />}>
        <Route path='home-v6' element={<HomeStyle6 />} />
      </Route>
      <Route path='home-v5' element={<Layout5 />}>
        <Route index element={<HomeStyle5 />} />
      </Route>
      <Route path='*' element={<ErrorPage />} />
    </Routes>
  )
}

export default App
