import { Icon } from '@iconify/react';
import React from 'react';
import ModalImage from 'react-modal-image';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';

export default function GallerySection({ sectionTitle, sectionTitleUp, data }) {
  return (
    <div className="container">
      <div className="cs_gallery_grid_1">
        <div className="cs_grid_item">
          <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} />
          <Spacing md="52" xl="52" lg="25" />
        </div>
        <div className="cs_grid_item"></div>
        {data?.map((item, index) => (
          <div className="cs_portfolio cs_style_1 cs_radius_20 overflow-hidden" key={index}>
            <div
              className="cs_portfolio_img d-block cs_bg_filed st_lightbox_item"
              style={{
                backgroundImage: `url(${item?.thumbnail})`,
              }}
            >
              <ModalImage small={item?.thumbnail} large={item?.image_url} alt="Gallery" />
              <span className="cs_link_hover">
                <i>
                  <Icon icon="fa6-solid:arrows-up-down-left-right" />
                </i>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
