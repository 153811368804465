import React from 'react';
import Section from '../components/Section';
import BannerSectionStyle10 from '../components/Section/BannerSection/BannerSectionStyle10';
import BannerSectionStyle3 from '../components/Section/BannerSection/BannerSectionStyle3';
import WorkingProcess from '../components/Section/WorkingProcess';
import { pageTitle } from '../helpers/PageTitle';

const workingProcessData = [
  {
    title: '1926',
    subTitle:
      `Perkumpulan Vereniging van Indische Artsen berubah namanya menjadi Vereniging Van Indonesische Genesjkundigen (VGI). Menurut Prof. Bahder Djohan (Sekretaris VIG selama 11 tahun -1928-1938), perubahan nama ini berdasarkan landasan politik yang menjelma dari timbulnya rasa nasionalisme (dimana dokter pribumi dianggap sebagai dokter kelas dua), sehingga membuat kata “indische” menjadi Indonesische” dalam VIG.
      <br /><br/>
      Dengan demikian, profesi dokter telah menimbulkan rasa kesatuan atau paling tidak meletakkan sendi-sendi persatuan. Bahder Djohan mengatakan pula, “tujuan VIG ialah menyuarakan pendapat dokter, dimana pada masa itu persoalan yang pokok ialah mempersamakan kedudukan antara dokter pribumi dengan dokter Belanda dari segi kualitasnya”.`,
    iconUrl: '/images/home_2/wording_process_icon_1.svg',
    number: '1',
  },
  {
    title: '1940',
    subTitle: `VIG mengadakan kongres di Solo. Kongres tersebut menugaskan Prof. Bahder Djohan untuk membina, dan memikirkan istilah baru dalam dunia kedokteran.
    <br/><br/>
    Saat itu telah berkumpul 3000 istilah baru dalam dunia kedokteran. Usaha VIG lainnya adalah peningkatan gaji (upah) dokter “melayu” agar mempunyai derajat yang sama dengan dokter Belanda, yang berhasil mencapai 70% dari jumlah semula (50%). Selain itu, pemberian kesempatan dan pendidikan bagi dokter “Melayu” menjadi asisten dengan prioritas pertama.`,
    iconUrl: '/images/home_2/wording_process_icon_2.svg',
    number: '2',
  },
  {
    title: '1943',
    subTitle: 'Dalam masa pendudukan Jepang, VIG dibubarkan dan diganti menjadi Jawa izi Hooko-Kai.',
    iconUrl: '/images/home_2/wording_process_icon_3.svg',
    number: '3',
  },
  {
    title: '30 Juli 1950',
    subTitle: `PB Perthabin (Persatuan Thabib Indonesia) yang diketuai Dr. Abdoelrasjid dan DP-PDI (Perkumpulan Dokter Indonesia) menyelenggarakan rapat.; Atas usul Dr. Seno Sastromidjojo dibentuklah panitia penyelenggara Muktamar Dokter Warganegara Indonesia (PMDWNI), yang diketuai Dr. Bahder Djohan. Panitia ini bertugas menyelenggarakan “Muktamar Dokter Warganegara Indonesia”. Kegiatan ini bertujuan untuk “mendirikan suatu perkumpulan dokter warganegara Indonesia yang baru, dan merupakan wadah representasi dunia dokter Indonesia, baik dalam maupun keluar negeri”.`,
    iconUrl: '/images/home_2/wording_process_icon_4.svg',
    number: '4',
  },
  {
    title: '22-25 September 1950',
    subTitle: `Muktamar pertama Ikatan Dokter Indonesia (MIDI) digelar di Deca Park yang kemudian menjadi gedung pertemuan Kotapraja Jakarta. (sekarang telah digusur) Sebanyak 181 dokter WNI (62 diantaranya datang dari luar Jakarta) menghadiri Muktamar tersebut. Dalam muktamar IDI itu, Dr. Sarwono Prawirohardjo (sekarang Prof.) terpilih menjadi Ketua Umum IDI pertama.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '5',
  },

  {
    title: '1951',
    subTitle: `IDI pertama kali menerbitkan Majalah Kedokteran Indonesia (MKI) yang kemudian ditetapkan sebagai majalah ilmiah resmi IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '6',
  },
  {
    title: '1953',
    subTitle: `IDI diterima menjadi anggota World Medical Association (WMA) yang menghimpun semua organisasi kedokteran di dunia. Pada tahun ini, Dr.H.R. Soeharto terpilih kedua kalinya menjabat sebagai Ketua Umum PB IDI
    IDI memprakarsai berdirinya Confederation of Medical Associationin Asia and Oceania (CMMAO) dan sejak itu, IDI aktif menjadi anggota organisasi tersebut.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '7',
  },
  {
    title: '1955-1956',
    subTitle: `Prof. Dr. Hendarmin terpilih menjadi Ketua Umum ketiga PB IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '8',
  },
  {
    title: '1956-1958',
    subTitle: `Prof. Dr. M Djoewari menjabat sebagai Ketua Umum keempat PB IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '9',
  },
  {
    title: '1958-1960',
    subTitle: `Dr. H. R Soeharto untuk ketiga kalinya menjabat sebagai Ketua Umum PB IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '10',
  },
  {
    title: '960-1970',
    subTitle: `Dr. H. Amino Gondhohutomo menduduki jabatan sebagai Ketua Umum PB IDI untuk keempat kalinya, yakni periode keempat, kelima, keenam dan ketujuh.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '11',
  },
  {
    title: '1969',
    subTitle: `IDI menyelenggarakan Musyawarah Kerja Sosial Kedokteran Indonesia. Musyawarah ini berhasil menyusun dan mensahkan Kode Etik Kedokteran Indonesia (Kodeki).`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '12',
  },
  {
    title: '1970-1972',
    subTitle: `Prof. Dr. Sadatun Soerjohardjo menjabat sebagai Ketua Umum kedelapan PB IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '13',
  },
  {
    title: '1972-1974',
    subTitle: `Prof. Dr. Sudarto Pringgoutomo menjabat sebagai Ketua Umum kesembilan PB IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '14',
  },
  {
    title: '1974-1976',
    subTitle: `Untuk kelima kalinya, Dr. H. Amino Gondhohutomo mengisi jabatan Ketua Umum kesepuluh PB IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '15',
  },
  {
    title: '1976',
    subTitle: `IDI menyelenggarakan Muktamar IDI di Semarang. Dalam Muktamar ini terpilih Dr. Utojo Sukaton sebagai Ketua Umum kesebelas PB IDI..`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '16',
  },
  {
    title: '1979',
    subTitle: `Untuk pertama kalinya, IDI menerbitkan Berita Ikatan Dokter Indonesia (BIDI). BIDI berkembang menjadi media komunikasi resmi IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '17',
  },
  {
    title: '1980',
    subTitle: `IDI memprakarsai berdirinya Medical Association of ASEAN (MASEAN), dan sejak itu menjadi anggota aktif organisasi tersebut.
    IDI menyempurnakan Anggaran Dasar (Add)/Anggaran Rumah Tangga (ART) IDI yang disahkan melalui Mukatamar Denpasar (1978).
    Muktamar ketujuhbelas IDI dilaksanakan di Solo. Dr. Abdullah Cholil, MPH disahkan sebagai Ketua Umum ketigabelas PB IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '18',
  },
  {
    title: '1981',
    subTitle: `IDI pertama kalinya aktif menyelenggarakan program Keluarga Berencana (KB). Bersama BKKBN, IDI mengembangkan program itu menjadi program KB Mandiri.
    IDI menyelenggarakan Musyawarah Kerja Nasional Etik Kedokteran. Dalam musyawarah tersebut Kodeki berhasil disahkan dan disempurnakan.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '19',
  },
  {
    title: '1982',
    subTitle: `Dalam Muktamar Manado (1982), untuk pertama kalinya disusun Rencana Kerja Jangka Panjang IDI. Pada Muktamar tersebut, Prof. Dr. Mahar Mardjono disahkan sebagai Ketua Umum keempat belas PB IDI, dan periode kepengurusan diubah menjadi 3 tahun.
    IDI pertama kali melaksanakan program pemberian penghargaan kepada para anggota berprestasi di bidang pengembangan ilmu dan teknologi kedokteran (penghargaan Dr. Wahidin Soedirohusodo).
    IDI pertama kalinya menyusun konsep Dokter Keluarga ssebagai alternative pengembangan praktik dokter swasta di Indonesia.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '20',
  },
  {
    title: '1985',
    subTitle: `Melalui Muktamar Bandung, IDI menetapkan Perhimpunan Dokter Spesialis (PDSp) dan Perhimpunan Dokter Seminat (PDSm) sebagai badan kelengkapan IDI yang bernaung di bawah IDI. Dr. Kartono Mohamad disahkan sebagai Ketua Umum kelimabelas PB IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '21',
  },
  {
    title: '1989',
    subTitle: `IDI menjadi tuan rumah Kongres Confederation of Medical Association and Oceania (CMMAO). Kongres yang digelar di Jakarta menetapkan Dr. Azrul Azwar (Ketua Umum PB IDI) sebagai presiden CMMAO.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '22',
  },
  {
    title: '1990',
    subTitle: `IDI menggelar Safe Motherhood Program. Program yang bertujuan mempercepat penurunan angka kematian ibu ini merupakan kerjasama IDI dengan Japan Medical Association.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '23',
  },
  {
    title: '1991',
    subTitle: `Pertama kalinya IDI menyusun Standar Pelayanan Medis.
    IDI mengadakan Muktamar keduapuluhsatu di Yogyakarta, Dr. Kartono Mohamad disahkan untuk kedua kalinya sebagai Ketua Umum ketujuhbelas PB IDI. Pada muktamar tahun 1991 ini, hymne IDI buah karya Ibu Tuti Nizar Z.A, secara resmi disahkan.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '24',
  },
  {
    title: '1993',
    subTitle: `IDI menggelar Rapat Kerja Nasional MKEK dan MP2A. Rapat kerja ini berhasil menyempurnakan pedoman pelaksanaan Kodeki dan tata cara pembelaan anggota.
    IDI pertama kalinya aktif ikut melaksanakan kampanye HIV/AIDS dengan melatih para dokter sebagai konselor HIV/AIDS`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '25',
  },
  {
    title: '1994',
    subTitle: `Muktamar keduapuluh dua IDI di Ujungpandang, mencanangkan perlunya dilaksanakan pendidikan dokter spesialis berbasis rumah sakit. Muktamar ini juga mensahkan Dr. Azrul Azwar, MPH sebagai Ketua Umum kedelapan belas PB IDI. Muktamar ini juga memilih Dr. Merdias Almatsier sebagai Ketua Terpilih IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '26',
  },
  {
    title: '1995',
    subTitle: `Bertempat di Bali, IDI melaksanakan kongres ke-47 World Medical Association.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '27',
  },
  {
    title: '1996',
    subTitle: `Dr. Azrul Azwar, MPH terpilih sebagai Presiden WMA pada World Medical Assembly ke-48 di Cape Town, Afrika Selatan.
    IDI meluncurkan homepage IDI yang dapat diakses melalui www.idi.or.id`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '28',
  },
  {
    title: '1997',
    subTitle: `IDI mengalami perkembangan pesat. Tercatat jumlah cabang sebanyak 242, IDI Wilayah sebanyak 24, PDSp sebanyak 24, PDSm sebanyak 23, dan anggota berjumlah 32.220 orang.
    Muktamar kedua puluh tiga IDI diadakan di Padang, Sumatera Bara. Forum ini mensahkan Dr. Merdias Almatsier sebagai Ketua Umum kesembilan belas PB IDI. IDI pun memilih DR.Dr. Ahmad Djojosugito sebagai Ketua Terpilih IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '29',
  },
  {
    title: '1998',
    subTitle: `IDI melakukan persiapan pembentukan Majelis Kolegium Kedokteran Indonesia (MKKI), yaitu lembaga baru dilingkungan IDI yang mengkoordinasikan seluruh kolegium ilmu dan bertanggung jawab dalam pendidikan profesi kedokteran, baik pendidikan dokter umum maupun pendidikan dokter spesialis.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '30',
  },
  {
    title: '2000',
    subTitle: `Oktober : IDI mendirikan Pusat Data dan Layanan Informasi IDI (Pusdalin IDI). Lembaga ini bertujuan meningkatkan kinerja Kepengurusan IDI, dalam menghadapi perkembangan zaman. Pusat data ini terbentuk berdasarkan SK PB No. 318/PBA4/10/2000.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '31',
  },
  {
    title: '2001',
    subTitle: `PB IDI membentuk tim UU Kesehatan Pejabat Negara. Pembentukan tim ini untuk memenuhi permintaan DPR RI yang akan menerbitkan RUU Kepresidenan.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '32',
  },
  {
    title: '2002',
    subTitle: `Januari : PB IDI mengadakan satu Round Table Discussion (RTD) tentang obat murah. Kegiatan ini berfungsi meluruskan berbagai isu yang menempatkan dokter sebagai variable yang sangat menentukan terhadap tingginya harga obat.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '33',
  },
  {
    title: '2008',
    subTitle: `IDI melaunching Kegiatan Dokter Kecil Award oleh Wakil Gubernur DKI Jakarta, sekaligus workshop Dokter Kecil.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '34',
  },
  
  {
    title: '2009',
    subTitle: `Muktamar IDI ke XVII Palembang mensahkan Dr. Prijo Sidipratomo, Sp.Rad sebagai Ketua Umum ke Sembilan Belas PB IDI.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '35',
  },
  {
    title: '2010',
    subTitle: `September 2010 : PB IDI mengeluarkan Surat Edaran nomor 1200/PB/A3/09/2010 tentang resertifikasi yang berisi antara lain pendaftaran administrasi P2KB untuk dokter spesialis maupun dokter umum melalui IDI cabang dan IDI wilayah`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '36',
  },
  {
    title: '2011',
    subTitle: `IDI menyelenggarakan Musyawarah Kerja Nasional di Pekanbaru, Riau.`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '37',
  },
  {
    title: '2012',
    subTitle: `Muktamar ke VIII di Makassar mensahkan Dr. Zaenal Abidin, MH sebagai Ketua Umum kedua puluh PB IDI. Dan mensahkan Prof. Dr. I Oetama Marsis, Sp.OG (K) sebagai Ketua Terpilih IDI.<br/>
    PB IDI telah merenovasi gedung utama maupun gedung belakang yang digunakan untuk operasional dan meningkatkan pelayanan<br/>
    PB IDI telah melakukan kajian dan menetapkan tarif dokter spesialis yang digunakan untuk negosiasi dengan BPJS<br/>
    Oktober : IDI menandatangani deklarasi Gerakan Dokter Selamatkan Indonesia bersama Komnas Pengendalian Tembakau<br/>
    Penandatanagan MoU antara IDI dengan Mabes Polri<br/>
    PB IDI bekerjasama dengan Singapore Medical Association (SMA) membuat kesepakatan terkait pedoman dan kode etik iklan layanan kesehatan dan kegiatan ilmiah di kedua Negara.<br/>
    Kerjasama dalam penilaian medis dan second opinion terhadap saksi/ tersangka/terdakwa yang perkaranya ditangani Komisi Pemberantasan Korupsi (KPK).<br/>
    Berdasarkan SK PB IDI nomor 2117/PB/A4/05/2012 tanggal 16 Mei 2012 diberikan Penghargaan Keteladanan Dokter Indonesia kepada Dr. Endang Rahayu Soedyaningsih, MPH, DR. PH<br/>`,
    iconUrl: '/images/home_2/wording_process_icon_5.svg',
    number: '38',
  },
];

export default function Sejarah() {
  pageTitle('Sejarah');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pricing_plan/banner_bg.svg"
        imgUrl="/images/logo-idi.png"
        title="Sejarah<br>Ikatan Dokter Indonesia"
        subTitle="Sekilas dan timeline tentang sejarah organisasi
        kedokteran Indonesia atau Ikatan Dokter
        Indonesia."
        btnText="Get Starter"
        btnUrl="/contact"
      />
      
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <WorkingProcess
          sectionTitle="Sejarah IDI"
          sectionTitleUp=""
          sectionTitleDown=""
          sectionSubTitle=""
          data={workingProcessData}
        />
      </Section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        className="cs_footer_margin_0"
      >
        <BannerSectionStyle10
          imgUrl="/images/pricing_plan/banner_img_2.png"
          title="Choose Your Plan and Invest in Your Health Today!"
        />
      </Section>
    </>
  );
}
