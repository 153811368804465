import React from 'react'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
const menuDataOne = [
  { title: 'About Us', href: '/about' },
  { title: 'Artikel', href: '/artikel' },
  { title: 'Contact Us', href: '/contact' }
  // { title: 'Departments', href: '/pengumuman' },
  // { title: 'Doctors', href: '/doctors' },
  // { title: 'Timetable', href: '/timetable' },
  // { title: 'Appointment', href: '/appointments' },
  // { title: 'Testimonials', href: '/' }
]
const menuDataTwo = [
  // { title: 'FAQs', href: '/' },
  { title: 'Privacy Policy', href: '/privacy-policy' },
  { title: 'Terms and Conditions', href: '/terms-conditions' }
]

export default function Footer() {
  return (
    <div
      className=' cs_style_4 cs_bg_filed overflow-hidden'
      style={{
        backgroundImage: `url(/images/susunan-pengurus/bg-1618x852.png)`
        // clipPath: 'polygon(0 24%, 100% 0%, 100% 99%, 0% 100%)'
      }}
    >
      <footer className='cs_footer cs_style_1 cs_white_color' style={{}}>
        <div
          className='cs_footer_logo_wrap'
          style={{
            backgroundImage: 'url(/images/footer_bg_1.svg)',

            // backgroundPosition: 'center',
            // backgroundSize: 'cover'

            // backgroundColor: '#000000C7',
            // backgroundImage: `url(${bgUrl})`,
            opacity: '0.9'

            // backgroundPosition: 'center',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover'
          }}
        >
          <div
            className='cs_footer_brand'
            style={{ backgroundImage: 'url(/images/footer_logo_bg.svg)' }}
          >
            <img
              src='/images/logo-fav.png'
              alt='Logo Icon'
              className='cs_footer_brand_icon'
            />
            <h2 className='cs_footer_brand_text'>IDI Jaktim</h2>
          </div>
        </div>
        <div className='cs_footer_main'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className='cs_footer_item'>
                  <TextWidget text='IDI Cabang <br />Jakarta Timur' />
                  <ContactInfoWidget />
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='cs_footer_item'>
                  <MenuWidget data={menuDataOne} />
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='cs_footer_item'>
                  <MenuWidget data={menuDataTwo} />
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='cs_footer_item'>
                  <Newsletter
                    title='Be Our Subscribers'
                    subTitle='To get the latest news about health from our experts'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='cs_footer_bottom cs_accent_bg'>
          <div className='container'>
            <div className='cs_footer_bottom_in'>
              <SocialWidget />
              <div className='cs_copyright'>
                Copyright © 2024 IDI Jaktim. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
