// ** Reducers Imports
import announcement from './actions/announcement'
import article from './actions/article'
import gallery from './actions/gallery'

const rootReducer = {
  announcement,
  article,
  gallery
}

export default rootReducer
