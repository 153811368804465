import React from 'react'
import { Outlet } from 'react-router-dom'
import FooterStyle4 from '../Footer/FooterStyle4'

export default function Layout5() {
  return (
    <>
      <Outlet />
      <FooterStyle4 />
    </>
  )
}
