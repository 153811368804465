import React from 'react'
import { Link } from 'react-router-dom'
import { timeAgo } from '../../utility'

export default function PostStyle2(data) {
  return (
    <div className='cs_post cs_style_2 cs_radius_20 overflow-hidden'>
      <Link to={data?.href} className='cs_post_thumb cs_view_mouse'>
        <img src={data?.thumbnail} alt='Post' />
      </Link>
      <div className='cs_post_info'>
        <div>
          <h2 className='cs_post_title cs_semibold cs_fs_18'>
            <Link to={`/artikel/${data?.slug}/${data?.id}/detail`}>
              {data?.title}
            </Link>
          </h2>
          <div className='cs_posted_by'>{timeAgo(data?.created_at)}</div>
        </div>
      </div>
    </div>
  )
}
