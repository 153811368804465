import React from 'react'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import NewsletterStyle4 from '../Widget/NewsletterStyle4'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
const menuDataOne = [
  { title: 'About Us', href: '/about' },
  { title: 'Departments', href: '/pengumuman' },
  { title: 'Doctors', href: '/doctors' },
  { title: 'Timetable', href: '/timetable' },
  { title: 'Appointment', href: '/appointments' }
]

const menuDataTwo = [
  { title: 'Testimonials', href: '/' },
  { title: 'Blog', href: '/artikel' },
  { title: 'Contact Us', href: '/' },
  { title: 'FAQs', href: '/' }
]
const menuDataThree = [
  { title: 'Privacy Policy', href: '/' },
  { title: 'Terms and Conditions', href: '/artikel' }
]

export default function FooterStyle4() {
  return (
    <footer className='cs_footer cs_style_2 cs_type_1 cs_accent_bg cs_white_color'>
      <div className='container'>
        <NewsletterStyle4
          title='Be Our Subscribers'
          subTitle='Join our newsletter to keep up to date <br />health from our experts'
        />
        <div className='cs_footer_in'>
          <div className='cs_footer_col'>
            <div className='cs_footer_item'>
              <TextWidget
                logoUrl='/images/footer_logo_white.svg'
                text='IDI Cabang Jakarta Timur'
              />
              <ContactInfoWidget />
            </div>
          </div>
          <div className='cs_footer_col'>
            <div className='cs_footer_item'>
              <MenuWidget data={menuDataOne} />
            </div>
          </div>
          <div className='cs_footer_col'>
            <div className='cs_footer_item'>
              <MenuWidget data={menuDataTwo} />
            </div>
          </div>
          <div className='cs_footer_col'>
            <div className='cs_footer_item'>
              <MenuWidget data={menuDataThree} />
              <SocialWidget />
            </div>
          </div>
        </div>
      </div>
      <div className='cs_footer_bottom'>
        <div className='container'>
          <div className='cs_copyright'>
            Copyright © 2023 IDI Jaktim. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  )
}
