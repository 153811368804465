import { Icon } from '@iconify/react';
import React, { useRef, useState } from 'react';
import { Lightbox } from 'react-modal-image';
import Slider from 'react-slick';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function AnnouncementCarousel({ sectionTitle, data }) {
  const sliderRef = useRef(null);
  const [image, setImage] = useState('');

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="container">
      <div className="cs_iconbox_carousel_1 cs_gap_20 position-relative">
        <div className="row">
          <div className="col-lg-4">
            <SectionHeading title={sectionTitle} />
            <Spacing md="200" lg="10" xl="150" />
            <Spacing md="30" xl="0" />
            <div className="cs_slider_navigation cs_style_2">
              <div className="cs_slider_prev cs_center" onClick={previous}>
                <img src="/images/icons/left_arrow_blue.svg" alt="Prev" />
              </div>
              <div className="cs_slider_next cs_center" onClick={next}>
                <img src="/images/icons/right_arrow_blue.svg" alt="Next" />
              </div>
            </div>
            <div className="cs_height_0 cs_height_lg_30" />
          </div>
          <div className="col-lg-8">
            <Slider
              ref={sliderRef}
              {...settings}
              className="cs_slider_activate"
            >
              {data?.map((item, index) => (
                <div key={index} className="cs_portfolio cs_style_1 cs_radius_20 overflow-hidden">
                  <div
                    className="cs_portfolio_img d-block cs_bg_filed st_lightbox_item"
                    style={{
                      backgroundImage: `url(${item?.imgUrl || item?.iconSrc})`,
                      cursor: 'pointer'
                    }}
                    onClick={e => setImage(item?.imgUrl)}>
                    <img src={item?.imgUrl || item?.iconSrc} alt={item?.title}/>

                    <span className="cs_link_hover">
                      <i>
                        <Icon icon="fa6-solid:arrows-up-down-left-right" />
                      </i>
                    </span>
                  </div>
                </div>
              ))}
            </Slider>
            {image ? <Lightbox small={image} large={image} alt="Pengumuman" onClose={e => setImage('')}/> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
