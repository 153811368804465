
// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "axios";
// ** Third Party Components
// **

export const announcementAll = createAsyncThunk(
  "announcement/getData",
  async (params) => {
    const response = await axios.get("/api/announcement", { params });
    return {
      params,
      data: response.data.data,
      totalPages: response.data.paging.totalPages,
      totalItems: response.data.paging.totalItems
    };
  }
);

export const announcementDetail = createAsyncThunk(
  "announcement/announcementDetail",
  async (id) => {
    const response = await axios.get(`/api/announcement/${id}`);
    return response.data;
  }
);

export const _ = createSlice({
  name: "announcement",
  initialState: {
    data: [],
    total: 1,
    params: {},
    selected: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(announcementAll.fulfilled, (state, action) => {
        state.data = action.payload.data || [];
        state.params = action.payload.params;
        state.total = action.payload.totalItems;
        state.selected = {}
      })
      .addCase(announcementDetail.fulfilled, (state, action) => {
        state.selected = action.payload?.data;
      })
      
  },
});

export default _.reducer;
