import React, { useEffect } from 'react'
// ** Third Party
import { Icon } from '@iconify/react'

import { Link, useParams } from 'react-router-dom'
// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { articleDetail } from '../redux/actions/article'

import Breadcrumb from '../components/Breadcrumb'
// import Post from '../components/Post';
import Section from '../components/Section'
// import BannerSectionStyle9 from '../components/Section/BannerSection/BannerSectionStyle9';
import Sidebar from '../components/Sidebar'
import Spacing from '../components/Spacing'
// import AuthorWidget from '../components/Widget/AuthorWidget';
// import CommentsWidget from '../components/Widget/CommentsWidget';
// import ReplyWidget from '../components/Widget/ReplyWidget';
import { isObjEmpty, pageTitle } from '../helpers/PageTitle'

export default function BlogDetails() {
  const { slug, id } = useParams()
  // ** Store Vars
  const dispatch = useDispatch()
  const article = useSelector((state) => state.article)
  useEffect(() => {
    dispatch(articleDetail(id))
  }, [dispatch, id, slug])

  pageTitle('Artikel Detail')
  return (
    <>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title={article.selected?.title} />
      </Section>
      {!isObjEmpty(article.selected) ? (
        <div className='container'>
          <div className='cs_blog_details_info'>
            <div className='cs_blog_details_info_left'>
              <div className='cs_blog_details_tags'>
                {article.selected?.tags?.map((item, index) => (
                  <Link key={index} to={item}>
                    {item}
                  </Link>
                ))}
              </div>
              <div className='cs_blog_details_date'>
                March 12, 2023 | Debri Bianca
              </div>
            </div>
            <div className='cs_social_links_wrap'>
              <h2>Share:</h2>
              <div className='cs_social_links'>
                <Link to='/'>
                  <Icon icon='fa-brands:facebook-f' />
                </Link>
                <Link to='/'>
                  <Icon icon='fa-brands:linkedin-in' />
                </Link>
                <Link to='/'>
                  <Icon icon='fa-brands:twitter' />
                </Link>
              </div>
            </div>
          </div>
          <Spacing md='55' />
          <img
            src={article.selected?.attachments[0]?.url}
            alt='Blog Details'
            className='w-100 cs_radius_20'
          />
          <Spacing md='90' lg='50' />
          <div className='row'>
            <div className='col-lg-8'>
              <div className='cs_blog_details'>
                {/* Render the HTML content */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: article.selected?.content
                  }}
                />
              </div>
              {/* <Spacing md="85" />
            <AuthorWidget
              imgUrl="/images/blog/author.png"
              name="Author Bio"
              description="John Smith is a freelance writer and content strategist with a passion for helping businesses tell their stories. With over 10 years of experience in the industry, John has worked with a wide range of clients, from startups to Fortune 500 companies. He holds a Bachelor's degree in English from the University of California, Los Angeles (UCLA), and is an avid reader and traveler in his free time. Follow him on Twitter @johnsmithwriter for the latest updates on his work."
            /> */}
              {/* <Spacing md="110" />
            <CommentsWidget title="Comments" /> */}
              {/* <Spacing md="92" />
            <ReplyWidget title="Leave a Reply" /> */}
            </div>
            <div className='col-lg-4'>
              <Sidebar />
            </div>
          </div>

          {/* <Spacing md="135" lg="100" />
        <h2 className="mb-0 cs_fs_40 cs_medium">Related Articles</h2>
        <Spacing md="57" />
        <div className="row cs_gap_y_40">
          {relatedBlog?.map((item, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <Post {...item} />
            </div>
          ))}
        </div> */}
        </div>
      ) : null}
      <Spacing md='200' xl='150' lg='110' />
      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section> */}
    </>
  )
}
