import React, { useEffect } from 'react';

import Breadcrumb from '../components/Breadcrumb';
import Section from '../components/Section';
import BannerSectionStyle9 from '../components/Section/BannerSection/BannerSectionStyle9';
import BlogSectionStyle2 from '../components/Section/BlogSection/BlogSectionStyle2';
import { pageTitle } from '../helpers/PageTitle';
// ** Store & Actions
import { useDispatch, useSelector } from "react-redux";
import { articleAll } from '../redux/actions/article';


export default function Article() {
  // ** Store Vars
  const dispatch = useDispatch();
  const article = useSelector((state) => state.article);

  useEffect(() => {
    dispatch(articleAll({page:1, size: 9}))
  }, [dispatch]);

  pageTitle('Artikel');
  return (
    <>
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title="Psychology and Life Style" />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <BlogSectionStyle2 data={article?.data} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
