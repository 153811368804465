import React, { useEffect } from 'react'

// import Hero from '../components/Hero'
import Section from '../components/Section'
import AboutSection from '../components/Section/AboutSection'
import Banner from '../components/Section/BannerSection'
import BlogSectionStyle4 from '../components/Section/BlogSection/BlogSectionStyle4'
// import RecomendPPDS from '../components/Section/DepartmentSection/RecomendPPDS'
import { pageTitle } from '../helpers/PageTitle'
// import FaqSection from '../components/Section/FaqSection';
import Announcement from '../components/Section/FeaturesSection/Announcement'
import GallerySection from '../components/Section/GallerySection'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { galleryAll } from '../../src/redux/actions/gallery'
import Headline from '../components/Section/Headline'
import { articleAll } from '../redux/actions/article'

const listAnnouncement = [
  {
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/idi-member.appspot.com/o/anouncement%2Fhari-bakti-dokter-indonesia-ke-115-idi-wilayah-dki-jakarta-PGP9LZZd.jpeg?alt=media&token=48bfd6ea-b202-40ed-901a-27e8ccba01c4',
    title: 'Hari Bakti Dokter Indonesia Ke - 115 IDI Wilayah DKI Jakarta',
    published_at: '11 bulan yang lalu',
    subTitle:
      'We understand that seeking medical care can be a stressful and emotional experience, and we strive to create a welcoming and supportive environment that puts our patients at ease and every one.'
  },
  {
    imgUrl:
      'https://firebasestorage.googleapis.com/v0/b/idi-member.appspot.com/o/anouncement%2Faksi-keprihatinan-PG4daiWc.jpeg?alt=media&token=a859cb24-3a43-4f5c-a92a-4876b86663be',
    title: 'Excellence',
    subTitle:
      'We are committed to providing excellent medical care and services to our patients. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality care possible.'
  },
  {
    imgUrl: '/images/about/portfolio_3_lg.jpeg',
    title: 'Integrity',
    subTitle: `We believe in practicing medicine with integrity and honesty. We are transparent in our communication and decision-making processes, and we always put our patient's interests first & provide best solution.`
  },
  {
    imgUrl: '/images/about/portfolio_3_lg.jpeg',
    title: 'Respect',
    subTitle:
      'We treat all individuals with respect and dignity, regardless of their background, beliefs, or circumstances. We believe that every person deserves to be treated with compassion and kindness.'
  },
  {
    imgUrl: '/images/about/portfolio_3_lg.jpeg',
    title: 'Teamwork',
    subTitle:
      'We believe in working collaboratively with our team membersvand other healthcare professionals to provide comprehensive and effective care to our patients.'
  }
]
// const faqData = [
//   {
//     title: 'What services does IDI Jaktim offer?',
//     content:
//       'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
//   },
//   {
//     title: 'How do I schedule an appointment with IDI Jaktim?',
//     content:
//       'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
//   },
//   {
//     title: 'Do you accept insurance?',
//     content:
//       'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
//   },
//   {
//     title: 'What should I bring to my appointment?',
//     content:
//       'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
//   },
//   {
//     title: 'How do I request a prescription refill?',
//     content:
//       'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.',
//   },
// ];

export default function Home() {
  // ** Store Vars
  const dispatch = useDispatch()
  const gallery = useSelector((state) => state.gallery)
  const article = useSelector((state) => state.article)

  useEffect(() => {
    dispatch(galleryAll({}))
    dispatch(articleAll({ page: 1, size: 4 }))
  }, [dispatch])

  pageTitle('Home')
  return (
    <>
      <Headline
        bgUrl='/images/home_1/hero_bg.jpeg'
        imgUrl='/images/home_1/hero_img.png'
        data={listAnnouncement}
        infoList={[
          {
            title: 'STR',
            subTitle: 'Surat Tanda Registrasi',
            iconUrl: '/images/contact/icon_1.svg'
          },
          {
            title: 'SIP',
            subTitle: 'Surat Izin Praktik',
            iconUrl: '/images/icons/ambulance.svg'
          },
          {
            title: 'Form Pengaduan',
            subTitle: 'Pengaduan',
            iconUrl: '/images/icons/pin.svg'
          },
          {
            title: 'P2KB',
            subTitle: 'Pendidikan Keprofesian',
            iconUrl: '/images/icons/pin.svg'
          }
        ]}
      />
      {/* <Hero
        title='Selamat Datang,<br>Dokter!'
        subTitle='Urus KTA, Rekomendasi, Pelaporan dan Izin Praktik sekarang bisa disini.
        Buat akun untuk menikmati semua layanan IDI Jaktim!'
        bgUrl='/images/home_1/hero_bg.jpeg'
        imgUrl='/images/home_1/hero_img.png'
        videoBtnText='See how we work'
        videoUrl='https://www.youtube.com/embed/VcaAVWtP48A'
        infoList={[
          {
            title: 'STR',
            subTitle: 'Surat Tanda Registrasi',
            iconUrl: '/images/contact/icon_1.svg'
          },
          {
            title: 'SIP',
            subTitle: 'Surat Izin Praktik',
            iconUrl: '/images/icons/ambulance.svg'
          },
          {
            title: 'Form Pengaduan',
            subTitle: 'Pengaduan',
            iconUrl: '/images/icons/pin.svg'
          },
          {
            title: 'P2KB',
            subTitle: 'Pendidikan Keprofesian',
            iconUrl: '/images/icons/pin.svg'
          }
        ]}
        // btnText="Register Now"
        btnUrl='/appointments'
      /> */}

      {/* Start About Section */}
      <Section topMd={16} topLg={12} topXl={7}>
        <AboutSection
          imgUrl='/images/home_1/about.png'
          spiningImgUrl='/images/home_1/about_mini.svg'
          title='Sambutan Ketua IDI Jaktim'
          subTitle='Dr. Huntal Napoleon, Sp.BP-RE, Subsp-LBL(K), FIHFAA, FRSPH'
          featureList={[
            {
              // featureListTitle: 'Dr. Huntal Napoleon, Sp.BP-RE, Subsp-LBL(K), FIHFAA, FRSPH',
              featureListSubTitle: `Euisque cursus metus vitae sedpharetra auctor semy mas interdum magla 
                fusce nec litora diam vestibulum andyus eget ipsum faucibus`
            }
          ]}
        />
      </Section>
      {/* End About Section */}

      {/* Start Gallery Section */}
      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle='Our Facilities and <br />Latest Activities'
          sectionTitleUp='Galeri Kegiatan'
          data={gallery?.data}
        />
      </Section>
      {/* End Gallery Section */}

      <Section topMd={160} topLg={120} topXl={70}>
        <BlogSectionStyle4
          sectionTitle='Latest Update'
          sectionTitleUp='Informasi Terkini'
          data={article?.data}
        />
      </Section>

      {/* Start Announcement Section */}
      <Section topMd={200} topLg={150} topXl={110}>
        <Announcement sectionTitle='Pengumuman' data={listAnnouncement} />
      </Section>
      {/* End Announcement Section */}

      {/* Start RecomendPPDS Section */}
      {/* <Section topMd={200} topLg={150} topXl={110}>
        <RecomendPPDS
          sectionTitle='Rekomendasi Pendidikan Lanjut'
          sectionTitleUp='IDI Jaktim Melayani'
          data={departmentData}
        />
      </Section> */}
      {/* End RecomendPPDS Section */}

      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl='images/home_1/cta_bg.svg'
          imgUrl='images/home_1/cta_img.png'
          title='Don’t Let Your Health Take a Backseat!'
          subTitle='Schedule an appointment with one of our experienced medical professionals today!'
        />
      </Section>
      {/* End Banner Section */}

      {/* Start FAQ Section */}
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        {/* <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        /> */}
      </Section>
      {/* End FAQ Section */}
    </>
  )
}
