import React from 'react'
import Section from '../components/Section'
// import AwardSectionStyle2 from '../components/Section/AwardSection/AwardSectionStyle2'
import BannerSectionStyle3 from '../components/Section/BannerSection/BannerSectionStyle3'
import BannerSectionStyle4 from '../components/Section/BannerSection/BannerSectionStyle4'
// import GallerySection from '../components/Section/GallerySection'
import TeamSectionStyle2 from '../components/Section/TeamSection/TeamSectionStyle2'
import { pageTitle } from '../helpers/PageTitle'

const teamData = [
  {
    imgUrl: '/images/about/ketua.png',
    name: 'Dr. Huntal Napoleon, Sp.BP-RE, Subsp-LBL(K), FIHFAA, FRSPH',
    designation: 'Ketua IDI Jakarta Timur',
    description:
      'With expertise in managing complex heart conditions and performing advanced cardiac procedures',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' }
    ],
    category: '2024'
  },
  {
    imgUrl: '/images/about/sekretaris.png',
    name: 'Dr. Fazilet Soeprapto, MPH',
    designation: 'Sekretaris IDI Jakarta Timur',
    description:
      'With expertise in treating acute illnesses and injuries in medicine physician',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' }
    ],
    category: '2024'
  }
]

export default function Penngurus() {
  pageTitle('Penngurus')
  return (
    <>
      <BannerSectionStyle3
        bgUrl='/images/about/banner_bg.svg'
        imgUrl='/images/about/banner-img-658x555.png'
        title='Welcome to <br />IDI Cabang Jakarta Timur'
        subTitle='IDI Jaktim adalah bagian dari Ikatan Dokter 
        Indonesia yang berwewenang di Jakarta Timur.'
      />
      <Section topMd={90} topLg={45} topXl={5}>
        <TeamSectionStyle2
          sectionTitleUp='Susunan Pengurus'
          subTitle='Susunan kepengurusan Ikatan Dokter
        Indonesia <br> Cabang Jakarta Timur'
          data={teamData}
        />
      </Section>
      {/* <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle='Our Facilities and <br />Latest Activities'
          sectionTitleUp='HAVE A LOOK AT'
          data={galleryData}
        />
      </Section>
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2
          sectionTitle='Winning Awards and <br />Recognition'
          sectionTitleUp='AWARDS'
          sectionSubTitle='We have been recognized for our commitment to <br />excellence in healthcare.'
          data={awardData}
        />
      </Section> */}
      <Section
        className='cs_footer_margin_0'
        topMd={170}
        topLg={120}
        topXl={80}
      >
        <BannerSectionStyle4
          bgUrl='images/susunan-pengurus/bg-1618x852.png'
          title='Don’t Let Your Health <br />Take a Backseat!'
          subTitle='Schedule an appointment with one of our experienced <br />medical professionals today!'
          center
        />
      </Section>
    </>
  )
}
