import parser from 'html-react-parser'
import React from 'react'

export default function BannerSectionStyle4({
  bgUrl,
  title,
  subTitle,
  center
}) {
  return (
    <div
      className=' cs_style_4 cs_bg_filed overflow-hidden'
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <div
        className={`cs_banner cs_style_4 cs_bg_filed  ${
          center ? 'text-center' : ''
        }`}
        style={{
          backgroundColor: '#000000C7',
          // backgroundImage: `url(${bgUrl})`,
          opacity: '0.8',

          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <h2 className='cs_banner_title cs_white_color cs_fs_48'>
          {parser(title)}
        </h2>
        <p className='cs_banner_subtitle cs_white_color cs_fs_20 m-0'>
          {parser(subTitle)}
        </p>
      </div>
    </div>
  )
}
