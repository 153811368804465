'use client'
import React from 'react'
// import Link from "next/link"
import { Link } from 'react-router-dom'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
const swiperOptions = {
  modules: [Autoplay, Pagination, Navigation],
  spaceBetween: 1,
  slidesPerView: 1,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  loop: true,

  // Navigation
  // navigation: {
  //   nextEl: '.h1n',
  //   prevEl: '.h1p'
  // }

  // Pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  }
}
export default function Headline({ bgUrl, imgUrl, data, infoList }) {
  return (
    <>
      {/*  Main Sllider Start  */}

      <section className='cs_hero cs_style_1 '>
        <Swiper
          {...swiperOptions}
          className='cs_hero_wrap main-slider-three main-slider-three__carousel owl-carousel owl-theme thm-owl__carousel'
        >
          <SwiperSlide className='item main-slider-three__slide-2'>
            <div className='main-slider-three__img'>
              <img src={imgUrl} alt='' className='float-bob-y' />
            </div>
            <div className='main-slider-three__shape-2 img-bounce'>
              <img src='images/shapes/main-slider-three-shape-2.png' alt='' />
            </div>
            <div className='main-slider-three__shape-3 float-bob-x'>
              <img src='images/shapes/main-slider-three-shape-3.png' alt='' />
            </div>
            <div className='main-slider-three__shape-4 float-bob-y'></div>
            <div className='main-slider-three__shape-5 zoominout'></div>
            <div className='container'>
              <div className='main-slider-three__content'>
                <h2 className='main-slider-three__title'>
                  Selamat Datang <br />{' '}
                  <span className='main-slider-three__color-text'>Dokter</span>{' '}
                  <br />
                  {/* <span className='main-slider-three__color-text-two'>
                    Dokter
                  </span>{' '}
                  Your <br />
                  Risk Free */}
                </h2>
                <p className='main-slider-three__text'>
                  Urus KTA, Rekomendasi, Pelaporan dan Izin Praktik sekarang
                  bisa disini.
                  <br />
                  Buat akun untuk menikmati semua layanan IDI Jaktim!
                </p>

                <div className='cs_hero_info_col'>
                  <Link
                    to={'https://member.idijaktim.org/register'}
                    target={'_blank'}
                    className='cs_btn cs_style_1'
                  >
                    <span>{'Register Now'}</span>
                    <i>
                      <img src='/images/icons/arrow_white.svg' alt='Icon' />
                      <img src='/images/icons/arrow_white.svg' alt='Icon' />
                    </i>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='item main-slider-three__slide-3'>
            <div className='main-slider-three__img'>
              <img src={imgUrl} alt='' className='float-bob-y' />
            </div>
            <div className='main-slider-three__shape-2 img-bounce'>
              <img src='images/shapes/main-slider-three-shape-2.png' alt='' />
            </div>
            <div className='main-slider-three__shape-3 float-bob-x'>
              <img src='images/shapes/main-slider-three-shape-3.png' alt='' />
            </div>
            <div className='main-slider-three__shape-4 float-bob-y'></div>
            <div className='main-slider-three__shape-5 zoominout'></div>
            <div className='container'>
              <div className='main-slider-three__content'>
                <h2 className='main-slider-three__title'>
                  Selamat Datang <br />{' '}
                  <span className='main-slider-three__color-text'>Dokter</span>{' '}
                  {/* <br />
                  <span className='main-slider-three__color-text-two'>
                    Sheildign
                  </span>{' '}
                  Your <br />
                  Risk Free */}
                </h2>
                <p className='main-slider-three__text'>
                  Login untuk mengurus, mengakses dan berbagai macam hal lainnya
                  <br />
                  terkait keanggotaan IDI Jaktim
                </p>
                <div className='cs_hero_info_col'>
                  <Link
                    to={'https://member.idijaktim.org'}
                    target={'_blank'}
                    className='cs_btn cs_style_1'
                  >
                    <span>{'SignIn Now'}</span>
                    <i>
                      <img src='/images/icons/arrow_white.svg' alt='Icon' />
                      <img src='/images/icons/arrow_white.svg' alt='Icon' />
                    </i>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className='container cs_hero_info_wrap cs_shadow_1 cs_white_bg cs_radius_15'>
          {infoList.map((item, index) => (
            <div className='cs_hero_info_col' key={index}>
              <div className='cs_hero_info d-flex align-items-center'>
                <div className='cs_hero_info_icon cs_center rounded-circle cs_accent_bg'>
                  <img src={item.iconUrl} alt='Icon' />
                </div>
                <div className='cs_hero_info_right'>
                  <h3 className='cs_hero_info_title cs_semibold'>
                    {item.title}
                  </h3>
                  <p className='cs_hero_info_subtitle'>{item.subTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* Main Sllider Start  */}

      <Swiper
        {...swiperOptions}
        className='main-slider-three__carousel owl-carousel owl-theme thm-owl__carousel'
        hidden
      >
        {data?.map((item, index) => (
          <SwiperSlide
            key={index}
            className='cs_portfolio cs_style_1 cs_radius_20 overflow-hidden'
          >
            <div
              className='cs_portfolio_img d-block cs_bg_filed st_lightbox_item'
              style={{
                backgroundImage: `url(${item?.imgUrl || item?.iconSrc})`,
                cursor: 'pointer'
              }}
            >
              <img src={item?.imgUrl || item?.iconSrc} alt={item?.title} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
