import React from 'react'
import Section from '../components/Section'
import BannerSectionStyle10 from '../components/Section/BannerSection/BannerSectionStyle10'
import BannerSectionStyle3 from '../components/Section/BannerSection/BannerSectionStyle3'
import FaqSectionStyle4 from '../components/Section/FaqSection/FaqSectionStyle4'
import { pageTitle } from '../helpers/PageTitle'

const faqData = [
  {
    title: 'Undang - Undang',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.'
  },
  {
    title: 'Peraturan Pemerintah',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.'
  },
  {
    title: 'Peraturan Menteri Kesehatan RI',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.'
  },
  {
    title: 'Peraturan Presiden',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.'
  },
  {
    title: 'Instruksi Presiden',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.'
  },
  {
    title: 'Kode Etik Kedokteran',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.'
  }
]
export default function Regulasi() {
  pageTitle('Regulasi')
  return (
    <>
      <BannerSectionStyle3
        bgUrl='/images/pricing_plan/banner_bg.svg'
        imgUrl='/images/banner/regulasi-658x555.png'
        title='Regulasi'
        subTitle='Daftar Regulasi dan Peraturan Pemerintah RI terbaru'
        btnText=''
        btnUrl='/contact'
      />

      <Section
        topMd={185}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
        className='cs_gray_bg_1'
      >
        <FaqSectionStyle4
          sectionTitle='Regulasi'
          data={faqData}
          faqImgUrl='/images/home_4/faq_img.jpeg'
          spiningImgUrl='/images/home_1/about_mini.svg'
        />
      </Section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        className='cs_footer_margin_0'
      >
        <BannerSectionStyle10
          imgUrl='/images/pricing_plan/banner_img_2.png'
          title='Choose Your Plan and Invest in Your Health Today!'
        />
      </Section>
    </>
  )
}
